import React, { useEffect, useState } from 'react'
import styles from './Audience.module.css'
import { useTranslation } from 'react-i18next'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import Header from 'components/Header/Header'
import { Box, Tab, Tabs } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useNavigate, useParams } from 'react-router-dom'
import Buddies from './Buddies/Buddies'
import Fans from './Fan/Fans'
import FansOf from './FansOf/FansOf'
import InviteReceived from './InviteReceived/InviteReceived'
import InviteSent from './InviteSent/InviteSent'
import Blocked from './Blocked/Blocked'


const Audience = () => {

    const { user_id } = useParams()
    const { t } = useTranslation()
    const { data: authReducerState } = useAuthReducer()
    const accessToken = useAccessToken()
    const navigate = useNavigate()

    const controller = new AbortController();
    const signal = controller.signal;

    const goBack = () => navigate(-1)

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={styles.screen} >
            <Header title={t('Audience')} goBack={goBack} />
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="visible arrows tabs example">

                            <Tab label={t('buddies')} value="1" className={styles.tablabel} />
                            <Tab label={t('followers')} value="2" className={styles.tablabel} />
                            <Tab label={t('following')} value="3" className={styles.tablabel} />
                            <Tab label={t('Received')} value="4" className={styles.tablabel} />
                            <Tab label={t('sent')} value="5" className={styles.tablabel} />
                            <Tab label={t('blocked')} value="6" className={styles.tablabel} />

                        </Tabs>
                        
                    </Box>
                    <TabPanel value="1" style={{ padding: '0px' }}>
                        <Buddies user_id={user_id} />
                    </TabPanel>
                    <TabPanel value="2" style={{ padding: '0px' }}>
                        <Fans user_id={user_id} />
                    </TabPanel >
                    <TabPanel value="3" style={{ padding: '0px' }}>
                        <FansOf user_id={user_id} />
                    </TabPanel>
                    <TabPanel value="4" style={{ padding: '0px' }}>
                        <InviteReceived user_id={user_id} />
                    </TabPanel>
                    <TabPanel value="5" style={{ padding: '0px' }}>
                        <InviteSent user_id={user_id} />
                    </TabPanel>
                    <TabPanel value="6" style={{ padding: '0px' }}>
                        <Blocked user_id={user_id} />
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
}

export default Audience