import React from 'react'
import { MdAddBox, MdGroups } from 'react-icons/md'
import { color } from '../../../hooks/Utils/color'
import ProfilePicture from '../../ProfilePicture/ProfilePicture'
import moment from 'moment';
import 'moment/locale/fr';
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks';
import { FaUsers } from 'react-icons/fa';
import { AiFillLike, AiFillMessage } from 'react-icons/ai';
import { IoHandLeftSharp } from 'react-icons/io5';
import { BsFillCheckSquareFill, BsTelegram } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../api/api';
import useReducerNotifAction from '../../../hooks/ReducerHooksAction/useReducerNotifAction';
import { useTranslation } from 'react-i18next';

const NotifItem = ({
  notif
}) => {

  const { t } = useTranslation()
  const accessToken = useAccessToken()
  const { UPDATE_NOTIFICATION } = useReducerNotifAction()
  const navigate = useNavigate()
  const MAX_LENGTH = 100;
  const { data: authData } = useAuthReducer()
  const timeAgo = moment(notif?.notif_created).locale(authData?.language).fromNow();


  const goToProfile = () => {
    navigate(`/profiles/user/${authData?.user?.user_username}`)
  }

  const goToViewOpinion = () => {
    navigate(`/opinion/select/${notif?.notif_agoid ? notif?.notif_agoid : 0}/${notif?.opinion?.sond_id}`)
  }

  const goToTransaction = () => {
    // navigate(`/profiles/user/${authData?.user?.user_id}/transactions`)
    navigate(`/profiles/user/${authData?.user?.user_username}`)

  }


  const approveBuddy = async () => {

    try {
      const body = { user_id: notif?.notif_from }
      const request = await api(
        'api/profile/approve-request-buddy',
        'POST',
        body,
        accessToken,
      )
      const response = await request.json()
      console.log('Response approve buddy:', response)
      if (request.ok && request.status === 200) {
        if (response.success) {

          UPDATE_NOTIFICATION({ ...notif, ...{ notif_state: true } })

          //   sendNotification(user_id, {
          //     title: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} a vu votre profil.`,
          //     body: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} a accepté votre demande.`,
          //     url: `https://myopinion.ci/profiles/user/${authStateContext?.user?.user_id}`
          //   })
        }
      }
    } catch ({ message }) {
      throw new Error(message)
    }
  }

  const cancelBuddy = async () => {
    try {
      const body = { user_id: notif?.notif_from }
      const request = await api(
        'api/profile/request-cancel-buddy',
        'DELETE',
        body,
        accessToken,
      )
      const response = await request.json()
      console.log('Response cancel buddy:', response)
      if (request.ok && request.status === 200) {
        if (response.success) {

          UPDATE_NOTIFICATION({ ...notif, ...{ notif_state: true } })

        }
      }
    } catch ({ message }) {
      throw new Error(message)
    }
  }


  const RenderHeaderNotif = () => {
    return (
      <div style={{ display: "flex", alignItems: 'center' }}>
        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', width: '50px', marginRight: '5px' }}>
          <ProfilePicture
            user={notif?.user_from}
            uri={notif?.user_from?.profile?.prof_picture}
            size={45}
          />
        </div>

        {authData?.language == 'fr' ? (
          <span style={{ marginLeft: '10px', fontSize: '14px' }}>
            {notif?.notif_content_fr?.split(" ")?.map((word, index) => {
              if (word === `${notif?.user_from?.user_surname}`) {
                return <span key={index} style={{ fontWeight: '600' }}>{word} </span>;
              } else if (word === `${notif?.user_from?.user_name}`) {
                return <span key={index} style={{ fontWeight: '600' }}>{word} </span>;
              } else {
                return <span key={index}>{word} </span>;
              }
            })}
          </span>
        ) : (
          <span style={{ marginLeft: '10px', fontSize: '14px' }}>
            {notif?.notif_content_en?.split(" ")?.map((word, index) => {
              if (word === `${notif?.user_from?.user_surname}`) {
                return <span key={index} style={{ fontWeight: '600' }}>{word} </span>;
              } else if (word === `${notif?.user_from?.user_name}`) {
                return <span key={index} style={{ fontWeight: '600' }}>{word} </span>;
              } else {
                return <span key={index}>{word} </span>;
              }
            })}
          </span>
        )}

      </div>
    )
  }


  const renderOpinionIcon = () => {
    if (notif?.notif_content_fr.indexOf('like'.toLowerCase()) > -1 || notif?.notif_content_fr.indexOf('aim'.toLowerCase()) > -1) {
      return (
        <AiFillLike size={30} color={color.info} />
      )
    }
    if (notif?.notif_content_fr.indexOf('like'.toLowerCase()) > -1 && notif?.notif_content_fr.indexOf('comment'.toLowerCase()) ||
      notif?.notif_content_fr.indexOf('aim'.toLowerCase()) > -1 && notif?.notif_content_fr.indexOf('comment'.toLowerCase())) {
      return (
        <AiFillLike size={30} color={color.info} />
      )
    }
    if (notif?.notif_content_fr.indexOf('voted'.toLowerCase()) > -1 || notif?.notif_content_fr.indexOf('voté'.toLowerCase()) > -1) {
      return (
        <BsFillCheckSquareFill size={30} color={'#28a745'} />
      )
    }

    // GESTION DES COMMENTAIRE
    if (notif?.notif_content_fr.indexOf('a commenté'.toLowerCase()) > -1 && notif?.notif_content_en.indexOf('commented'.toLowerCase()) > -1) {
      return (
        <BsTelegram size={30} color={color.primary} />
      )
    }
    if (notif?.notif_content_fr.indexOf('répondu à votre commentaire'.toLowerCase()) > -1) {
      return (
        <BsTelegram size={30} color={color.primary} />
      )
    }
    if (notif?.notif_content_fr.indexOf('commentaire sous votre opinion'.toLowerCase()) > -1) {
      return (
        <BsTelegram size={30} color={color.primary} />
      )
    }
    if (notif?.notif_content_fr.indexOf('vous a mentionné dans un commentaire'.toLowerCase()) > -1) {
      return (
        <BsTelegram size={30} color={color.primary} />
      )
    }
    // GESTION DES TOP COMMENTAIRE
    if (notif?.notif_content_en.indexOf('you have a top comment'.toLowerCase()) > -1) {
      return (
        <BsTelegram size={30} color='#ffd700' />
      )
    }
    // GESTION DES SUGGESSIONS
    if (notif?.notif_content_fr.indexOf('top'.toLowerCase()) == -1 && notif?.notif_content_fr.indexOf('sugges'.toLowerCase()) > -1) {
      return (
        <IoHandLeftSharp size={30} color={color.info} />
      )
    }
    // GESTION DES TOP SUGGESSIONS 
    if (notif?.notif_content_fr.indexOf('top'.toLowerCase()) > -1 && notif?.notif_content_fr.indexOf('sugges'.toLowerCase()) > -1) {
      return (
        <IoHandLeftSharp size={30} color='#ffd700' />
      )
    } else {
      return (
        <MdAddBox size={30} color={color.primary} />
      )
    }


  }


  if (notif?.notif_type === 0) {
    return (
      <div style={{ borderBottom: '5px #f1f1f1 solid', padding: '10px' }}>

        <div style={{ display: "flex", justifyContent: 'flex-end', marginBottom: '-5px' }}>
          <b style={{ fontSize: '11px' }}>
            {timeAgo}
          </b>
        </div>

        <div style={{ display: "flex", alignItems: 'center' }}>

          <div style={{ width: '30px', marginRight: '3px' }}>

            {renderOpinionIcon()}
            {/* <MdAddBox size={30} color={color.secondary} /> */}
          </div>
          <div onClick={goToViewOpinion} style={{ cursor: 'pointer' }}>
            {RenderHeaderNotif()}
          </div>
        </div>

        <div onClick={goToViewOpinion} style={{ cursor: 'pointer', display: "flex", alignItems: 'center', borderRadius: '5px', border: `0.5px #c0c0c0 solid`, marginTop: '10px', padding: '5px' }}>

          {(
            notif?.opinion?.sond_picture &&
            notif?.opinion?.sond_picture !== "NULL" &&
            notif?.opinion?.sond_picture?.split('.')?.pop() !== 'mp4' &&
            notif?.opinion?.sond_picture?.split('.')?.pop() !== 'avi' &&
            notif?.opinion?.sond_picture?.split('.')?.pop() !== 'mov'
          ) ?
            <div>
              {notif?.opinion?.sond_picture && notif?.opinion?.sond_picture !== "NULL" ?
                <img src={notif?.opinion?.sond_picture}
                  width={65}
                  height={60}
                  alt=''
                  style={{
                    margin: '0px',
                    padding: '0px',
                    width: '65px',
                    height: '50px',
                    cursor: 'pointer',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    borderRadius: '5px'
                  }} />
                : null}
            </div>
            : null}


          <span style={{ fontSize: '13px', marginLeft: '10px' }}>
            {notif?.opinion?.sond_caption < MAX_LENGTH ?
              notif?.opinion?.sond_caption
              :
              `${notif?.opinion?.sond_caption?.substring(0, MAX_LENGTH)}...`
            }
          </span>

        </div>

      </div>
    )
  }

  if (notif?.notif_type === 1) {
    return (
      <div style={{ borderBottom: '5px #f1f1f1 solid', padding: '10px' }}>

        <div style={{ display: "flex", justifyContent: 'flex-end', marginBottom: '-5px' }}>
          <b style={{ fontSize: '11px' }}>
            {timeAgo}
          </b>
        </div>

        <div onClick={goToProfile} style={{ display: "flex", alignItems: 'center', cursor: 'pointer' }}>
          <div style={{ width: '30px', marginRight: '3px' }}>
            <FaUsers size={30} color={color.secondary} />
          </div>
          {RenderHeaderNotif()}
        </div>

        {!notif?.notif_state && notif?.notif_content_fr.indexOf('approuvé'.toLowerCase()) === -1 ?
          <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>

            <button onClick={approveBuddy} style={{ width: '30%', paddingBottom: '4px', color: 'white', backgroundColor: color.primary, borderRadius: '3px', }}>
              <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '10px' }}>{t('approve')}</span>
            </button>

            <button onClick={cancelBuddy} style={{ width: '30%', paddingBottom: '4px', color: color.primary, border: `1px ${color.primary} solid`, borderRadius: '3px', }}>
              <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '10px' }}>{t('cancel')}</span>
            </button>

          </div>
          : null}

      </div>
    )
  }

  if (notif?.notif_type === 2) {
    return (
      <div style={{ borderBottom: '5px #f1f1f1 solid', padding: '10px' }}>

        <div style={{ display: "flex", justifyContent: 'flex-end', marginBottom: '-5px' }}>
          <b style={{ fontSize: '11px' }}>
            {timeAgo}
          </b>
        </div>

        <div style={{ display: "flex", alignItems: 'center' }}>

          <div style={{ width: '30px', marginRight: '3px' }}>
            <MdGroups size={30} color={color.secondary} />
          </div>
          {RenderHeaderNotif()}
        </div>

        {/* {!notif?.notif_state &&
          <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>

            <button style={{ width: '30%', paddingBottom: '4px', color: 'white', backgroundColor: color.primary, borderRadius: '3px', }}>
              <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '10px' }}>Approve</span>
            </button>

            <button style={{ width: '30%', paddingBottom: '4px', color: color.primary, border: `1px ${color.primary} solid`, borderRadius: '3px', }}>
              <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '10px' }}>Annuler</span>
            </button>

          </div>
        } */}

      </div>
    )
  }

  if (notif?.notif_type === 3) {
    return (
      <div style={{ borderBottom: '5px #f1f1f1 solid', padding: '10px' }}>

        <div style={{ display: "flex", justifyContent: 'flex-end', marginBottom: '-5px' }}>
          <b style={{ fontSize: '11px' }}>
            {timeAgo}
          </b>
        </div>

        <div style={{ display: "flex", alignItems: 'center' }}>
          <div style={{ width: '30px', marginRight: '3px' }}>
            <AiFillMessage size={30} color={color.primary} />
          </div>
          {RenderHeaderNotif()}
        </div>

      </div>
    )
  }

  if (notif?.notif_type === 4) {
    return (
      <div style={{ borderBottom: '5px #f1f1f1 solid', padding: '10px' }}>

        <div style={{ display: "flex", justifyContent: 'flex-end', marginBottom: '-5px' }}>
          <b style={{ fontSize: '11px' }}>
            {timeAgo}
          </b>
        </div>

        <div style={{ display: "flex", alignItems: 'center' }}>
          <div style={{ width: '30px', marginRight: '3px' }}>
            <img src={notif?.recompense?.type?.type_recom_logo_url} width={30} height={25} alt='' />
          </div>
          <div onClick={goToTransaction} style={{ cursor: 'pointer' }}>
            {RenderHeaderNotif()}
          </div>
        </div>

      </div>
    )
  }

}

export default NotifItem