import React, { useEffect, useState } from 'react'
import './LoginForm.style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'
import { api } from '../../api/api';
import { Link, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import Select from 'react-select'
import FacebookLogin from '@greatsumini/react-facebook-login';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';
import ButtonSubmit from '../../components/ButtonSubmit/ButtonSubmit';
import TextInput from '../../components/TextInput/TextInput';
import { useTranslation } from 'react-i18next';
import { useGoogleLogin } from '@react-oauth/google';
import { ValidateEmail } from '../../hooks/Utils/parsing';
import ButtonGmail from '../../components/ButtonGmail/ButtonGmail/ButtonGmail';
import ButtonFacebook from '../../components/ButtonFacebook/ButtonFacebookUI/ButtonFacebook';
import AppleLogin from 'react-apple-login'
import ButtonApple from '../ButtonApple/ButtonApple';
import useReducerAuthAction from '../../hooks/ReducerHooksAction/useReducerAuthAction';
import ModelAlert from '../ModalAlert/ModelAlert';
import { color } from '../../hooks/Utils/color';
import { Alert } from '@mui/material';


const LoginForm = () => {

    // STATES
    const { t } = useTranslation()
    const navigate = useNavigate()
    const language = localStorage.getItem('language')

    const { LOGIN, UPDATE_LANGUAGE } = useReducerAuthAction()

    const [isLoading, setIsLoading] = useState(false)
    const [isGoogleLoading, setIsGoogleloading] = useState(false)
    const [isFacebookLoading, setIsFacebookLoading] = useState(false)
    const [isAppleLoading, setIsAppleLoading] = useState(false)

    const [showAlert, setShowAlert] = useState(false)

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [IPAddress, setIPAddress] = useState('192.168.1.1');
    const languages = [
        {
            label: t('french_france'),
            value: 'fr'
        },
        {
            label: t('english_us'),
            value: 'en'
        },
    ]


    /* CHANGE LANGUAGE */
    const handleChangeLanguage = (value) => {
        i18next.changeLanguage(value)
        UPDATE_LANGUAGE(value)
    }


    /* CHECK FORM */
    const checkForm = async (e) => {
        e.preventDefault();
        if (!ValidateEmail(email.trim())) {
            setError(t('invalid_email'))
            return
        }
        if (password.length < 8) {
            setError(t('invalid_password'))
            return
        }
        setError('')
        handleSubmit()
    }


    /* GET IP ADDRESS */
    useEffect(() => {
        const fetchIPAddress = async () => {
            try {
                const response = await axios.get('https://geolocation-db.com/json/')
                // console.log(response.data)
                if (response.status === 200) {
                    setIPAddress(response.data.IPv4)
                }
            } catch (e) {
                console.warn(e);
            }
        }
        fetchIPAddress()
    }, [])


    const fetchGoogleAccount = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            console.log('response tokenResponse:', tokenResponse)
            try {
                setIsGoogleloading(true)
                const request = await axios.get('https://www.googleapis.com/userinfo/v2/me', {
                    headers: {
                        'Authorization': `Bearer ${tokenResponse.access_token}`
                    }
                })
                const response = request.data
                console.log('Response fetch google account:', response)
                if (request.status === 200) {
                    const provider = {
                        user_email: response?.email,
                        user_oauth_provider: 'google',
                        user_oauth_id: response?.id,
                        user_surname: response?.given_name,
                        user_name: response?.name,
                        prof_picture: response?.picture,
                        user_ip: IPAddress,
                    }
                    socialLogin(provider)
                } else {
                    setIsGoogleloading(false)
                    setError(t('no_internet_connection'))
                }
            } catch (error) {
                setIsGoogleloading(false)
                setError(t('no_internet_connection'))
                console.warn('Error fetch google account:', error.message);
            }
        },
    });


    const socialLogin = async (data) => {
        try {
            console.log('Form data social login:', data)
            const request = await api('api/social-login', 'POST', data)
            const response = await request.json()
            console.log('Response social login:', response)
            if (request.ok && request.status === 200) {
                LOGIN(response)
            } else {
                setError(t('no_internet_connection'))
            }
            setIsGoogleloading(false)
            setIsFacebookLoading(false)
        } catch (e) {
            // socialLogin(data)
            console.warn('Error login social:', e);
        }
    }


    /* LOGIN HANDLER */
    const handleSubmit = async (e) => {
        setIsLoading(true)
        try {
            const body = {
                user_email: email,
                user_password: password,
                user_ip: IPAddress,
            }
            const request = await api('api/login', 'POST', body)
            const response = await request.json()
            // console.log(response)
            if (request.ok && request.status === 200) {
                LOGIN(response)
                setError('')
            } else {
                setError(t('incorrect_email_address_or_password'))
            }
            setIsLoading(false)
        } catch (error) {
            setError(t('an_error_occurred_please_try_again_later'));
            setIsLoading(false)
            console.warn(error.message);
        }
    };


    /* LOGIN APPLE */
    const appleResponse = response => {
        try {
            setError(language == 'fr' ? 'La connexion avec un compte apple est momentanément indisponible.' : 'Logging in with an apple account is temporarily unavailable.')
            console.log('Response login apple:', response)
            const data = {
                authorization: {
                    code: "cdb63f3b5ca074f429de3e1be61231bfd.0.mrtxw.pMGBMEOj75b1cnPHhzCRNQ",
                    id_token: "eyJraWQiOiJXNldjT0tCIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiYXVkIjoiY29tLm15b3Bpbmlvbi53ZWIuY2kiLCJleHAiOjE2Nzc2ODMyMjUsImlhdCI6MTY3NzU5NjgyNSwic3ViIjoiMDAxMzc2LjI2ODFlOWRmNzY3NDQzOGZhNjYyMzc5NjA5Y2EzODQ1LjE1MDciLCJjX2hhc2giOiJXYnI0a1ctRWJMekF2VGtKNTNZeDFBIiwiZW1haWwiOiJqb2VsZGJqcEBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6InRydWUiLCJhdXRoX3RpbWUiOjE2Nzc1OTY4MjUsIm5vbmNlX3N1cHBvcnRlZCI6dHJ1ZX0.KGz23_Lh4wHPwsFc-TVOtVAZLOppwxt3KwUP4jUegzp0z351HqGFqrJd1J1tEUBnB2mWOFSkPsPuEdvBEehlVy94cMel56Fd_tJV9vEvjNYZObPr2PY6Y5N7uqCfzY9P9g6qsp2CQLzXWHV7AT1cZVeYGpkuKV8IZ0XKJg8RLaFXZ1VoHO-MZokTB24RBQVC5U9woRQctLByFFVo7WM2s2DdLd-PeojfHcGii86jPNeziMUbsqbQFGSLzV8hYrOCnY2CQA2nIf5HKIQDsrpc1g4wanWuSmuzJwoczc5dZLJyO719aFxIKteH40fH4unGSYMsRhFcZdUS60A4DXCkHw",
                },
                user: {
                    email: "joeldbjp@gmail.com",
                    name: {
                        firstName: "Joel",
                        lastName: "Parfait",
                        middleName: ""
                    }
                }
            }
            if (!response.error && response?.user) {
                const provider = {
                    user_email: response?.user?.email?.toLowerCase(),
                    user_oauth_provider: 'apple',
                    user_oauth_id: response?.authorization?.code,
                    user_surname: response?.user?.firstName,
                    user_name: response?.user?.lastName,
                    prof_picture: '',
                    user_ip: IPAddress,
                }
                socialLogin(provider)
            }
        } catch (error) {
            setError(language == 'fr' ? 'La connexion avec un compte apple est momentanément indisponible.' : 'Logging in with an apple account is temporarily unavailable.')
            console.error(error);
        }
    };


    /* RENDER BUTTON APPLE */
    const renderAppleButton = () => {
        return (
            <AppleLogin
                clientId={process.env.REACT_APP_APPLE_ID_CLIENT}
                redirectURI={process.env.REACT_APP_AUTH_REDIRECT_URI}
                usePopup={false}
                callback={appleResponse}
                scope="email name"
                responseMode="query"
                render={renderProps => (
                    <ButtonApple
                        onClick={renderProps.onClick}
                    />
                )}
            />
        )
    }


    /* RENDER BUTTON APPLE */
    const renderFacebookButton = () => {
        return (
            <LoginSocialFacebook
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                onResolve={(response) => {
                    console.log('Get Profile Success!', response.data);
                    const provider = {
                        user_email: response.data?.email?.toLowerCase(),
                        user_oauth_provider: 'facebook',
                        user_oauth_id: response.data?.id,
                        user_surname: response.data?.name,
                        user_name: '',
                        prof_picture: response.data?.picture?.data?.url,
                        user_ip: IPAddress,
                    }
                    setIsFacebookLoading(true)
                    socialLogin(provider)
                }}
                onReject={(error) => {
                    console.log('Login Failed!', error);
                }}>

                <FacebookLoginButton />

            </LoginSocialFacebook>
        )
    }


    return (

        <div className='loginform'>

            <div className='container'>

                <div style={{ marginBottom: '20px' }}>
                    <img src={require('../../assets/app/wonko_full.png')}
                        className='logo-myopinion'
                        alt=''
                    />
                    <h3>{t('login')}</h3>
                </div>

                <form onSubmit={checkForm} >
                    {error &&
                        <Alert severity="error" style={{ marginBottom: '20px' }}>{error}</Alert>
                    }
                    <TextInput
                        value={email}
                        setValue={setEmail}
                        type='text'
                        placeholder={t('email')}
                        style={{
                            width: '100%',
                            marginBottom: '5%'
                        }}
                    />
                    <TextInput
                        value={password}
                        setValue={setPassword}
                        type='password'
                        placeholder={t('password')}
                        style={{
                            width: '100%',
                            marginBottom: '8%'
                        }}
                    />

                    <ButtonSubmit
                        value={t('login')}
                        isLoading={isLoading}
                        loadBoostrapColor={'light'}
                        style={{
                            width: '100%',
                            color: 'white',
                            marginBottom: '5%',
                            backgroundColor: color.primary
                        }}
                    />

                </form>

                <Link to={'/forget-password'} className='forget-password-text'>
                    {t('forgot_your_password')}
                </Link>

                {/*  <p className='connect-with'>
                    - {t('connect_with')} -
                </p> */}


                {/* <div className='social'>
                    <ButtonGmail onClick={() => fetchGoogleAccount()} isLoading={isGoogleLoading} style={{ marginBottom: '5%' }} />
                    {renderFacebookButton()}
                    {renderAppleButton()}
                </div> */}

                <p className='connect-with'>
                    - {t('or')} -
                </p>

                <ButtonSubmit
                    onClick={() => navigate('/register')}
                    value={t('create_an_account')}
                    loadBoostrapColor={'light'}
                    style={{
                        width: '100%',
                        color: 'white',
                        marginBottom: '30px',
                        backgroundColor: color.primary
                    }}
                />

                <Select
                    placeholder='Langue'
                    options={languages}
                    isSearchable={false}
                    defaultValue={languages.filter(items => items.value === language)}
                    onChange={newValue => handleChangeLanguage(newValue.value)}
                    className='select-language'
                />

                <div className='terms-conditions'>
                    {/* <a style={{ fontSize: '13px', marginRight: '5px' }}
                        href={language === 'en' ? 'https://myopinion.ci/legal/en/terms-and-conditions/' : 'https://myopinion.ci/legal/fr/conditions-generales/'}>
                        {t('terms_and_conditions')}
                    </a>

                    <a style={{ fontSize: '13px', marginLeft: '5px' }}
                        href={language === 'en' ? 'https://myopinion.ci/legal/en/privacy-policy/' : 'https://myopinion.ci/legal/fr/politique-de-confidentialite/'}>
                        {t('privacy_policy')}
                    </a> */}

                    {language === 'fr' ?
                        <p className='policy'>
                            En me connectant je confirme avoir lu et acceptez
                            <a href={t('link_terms_of_use')}> les conditions générales</a> et
                            <a href={t('link_privacy_policy')}> la politique de confidentialité</a>.
                        </p>
                        :
                        <p className='policy'>
                            By logging in I confirm that I have read and accept
                            <a href={t('link_terms_of_use')}> the general conditions</a> and
                            <a href={t('link_privacy_policy')}> conditions and the privacy policy</a>.
                        </p>
                    }
                </div>

                <p className='copyrigth'>
                    Social Hub © {new Date().getFullYear()}
                </p>

                <img src={require('../../assets/images/afdb.png')} alt='' style={{ width: 80, marginBottom: 30 }} />

            </div>

            <ModelAlert
                message={language === 'fr' ? 'Bientôt disponible.' : 'coming soon.'}
                show={showAlert}
                setShow={setShowAlert}
            />

        </div>
    )
}

export default LoginForm