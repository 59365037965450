import React, { useEffect, useState } from 'react'
import styles from './Agora.module.css'
import { useTranslation } from 'react-i18next'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks';
import LoadingPage from '../../components/LoadingPage/LoadingPage';
import { api } from '../../api/api';
import Header from '../../components/Header/Header';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MdGroups } from 'react-icons/md';
import { color } from '../../hooks/Utils/color';
import ButtonSubmit from '../../components/ButtonSubmit/ButtonSubmit';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { isMobile } from 'react-device-detect';
import { TbPlugConnected } from 'react-icons/tb';
import { AiOutlineDisconnect } from 'react-icons/ai';
import { Modal, Spinner } from 'react-bootstrap';
import TextInput from '../../components/TextInput/TextInput';
import TopBar from '../../components/Topbar/Topbar';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Switch, TextField } from '@mui/material';

const Agora = () => {

  const { t } = useTranslation()
  const { data: authData } = useAuthReducer()
  const accessToken = useAccessToken()
  const navigate = useNavigate()
  const [agoras, setAgoras] = useState([])
  const [requests, setRequests] = useState([])
  const [invitations, setInvitations] = useState([])
  const [agoraToKnow, setAgoraToKnow] = useState([])
  const [agoraToKnowMuted, setAgoraToKnowMuted] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const [agoraTitle, setAgoraTitle] = useState('')
  const [agoraDescription, setAgoraDescription] = useState('')
  const [showCreateAgora, setShowCreateAgora] = useState(false)
  const [isLoadingCreate, setIsLoadingCreate] = useState(false)

  const [isPrivate, setIsPrivate] = useState(true)

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };



  const handleOpen = () => {
    setShowCreateAgora(true)
  }

  const handleClose = () => {
    setShowCreateAgora(false)
  }



  useEffect(() => {
    fetchAgoraList()
    agoraYouMayKnow()
  }, [])

  const fetchAgoraList = async () => {
    console.log('Fetch agora list...')
    try {
      const request = await api('api/agoras', 'GET', null, accessToken)
      const response = await request.json()
      // console.log('Response agora list: ', response.agoras);
      if (request.ok && request.status === 200) {
        if (response.success) {
          setAgoras(response.agoras)
          setRequests(response.requests)
          setInvitations(response.invitations)
        }
      }
      setIsLoading(false)
    } catch (error) {
      fetchAgoraList()
    }
  }


  const agoraYouMayKnow = async () => {
    console.log('Get agora you may know...')
    try {
      const request = await api(`api/agoras/agora-to-know`, 'GET', {}, accessToken);
      const response = await request.json()
      // console.log('Reponse agora you may know:', response?.agoras)
      if (request?.status === 200) {
        if (response?.success) {
          setAgoraToKnow(response?.agoras)
          setAgoraToKnowMuted(response?.muted_agoras)
        }
      }
    } catch (error) {
      agoraYouMayKnow()
      console.log('Error get buddies-to-know:', error)
    }
  }


  const joinAnAgora = async (ago_id) => {
    console.log('Response join an agora ' + ago_id + '...')
    try {
      const body = { agm_agora: ago_id }
      const request = await api('api/agoras/join', 'POST', body, accessToken)
      const response = await request.json()
      // console.log('Response join an agora:', response,)
      if (request.ok && request.status === 200) {
        if (response.success) {
          fetchAgoraList()
          agoraYouMayKnow()
          // sendNotification(response?.moderator, {
          //     title: `${AuthContextState?.user?.user_surname} ${AuthContextState?.user?.user_name} a demandé à rejoindre votre agora.`,
          //     body: `Rendez-vous dans la liste des membres de votre agora pour l'accepter.`,
          // })
        }
      }
    } catch ({ message }) {
      throw new Error(message)
    }
  }


  // DELETE DEMANDE L'AGORA
  const deleteJoin = async (ago_id) => {
    try {
      const body = { agm_agora: ago_id }
      const request = await api(
        'api/agoras/members/delete-join',
        'POST',
        body,
        accessToken,
      )
      const response = await request.json()
      console.log('Response delete join', response.message)
      if (request.ok && request.status === 200) {
        fetchAgoraList()
        agoraYouMayKnow()
      }
    } catch (error) {
      console.log('Delete join an agora errorrr', error.message)
    }
  }



  const createAgora = async () => {
    try {
      setIsLoadingCreate(true)
      const body = {
        ago_title: agoraTitle,
        ago_presentation: agoraDescription,
      }
      const request = await api('api/agoras', 'POST', body, accessToken)
      const response = await request.json()
      setIsLoadingCreate(false)
      if (request.ok && request.status === 200) {
        if (response.success) {

          handleClose()
          fetchAgoraList()
          agoraYouMayKnow()
          setShowCreateAgora(false)

          const agora = response?.agora
          navigate(`/agora/${agora.ago_id}`, { state: { agora_title: agora?.ago_title } })
          
        }
      }
    } catch (error) {
      setIsLoadingCreate(false)
      console.error('Error create agora:', error)
    }
  }



  if (isLoading) {
    return (
      <div style={{ width: '100%', backgroundColor: 'white' }}>
        {isMobile ?
          <TopBar />
          :
          <Header title={t('groups')} />
        }
        <div style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <LoadingPage />
        </div>
      </div>
    )
  }


  const RenderAgora = ({ agora }) => {
    return (
      <div onClick={() => navigate(`/agora/${agora.ago_id}`, { state: { agora_title: agora?.ago_title } })}
        className={styles.agoraContainer}>
        {agora?.ago_picture ?
          <div className={styles.pictureContainer}>
            <img src={agora?.ago_picture} alt='' width={40} />
          </div>
          :
          <div className={styles.iconContainer}>
            <MdGroups color={color.white} className={styles.agoraIcon} />
          </div>
        }
        <h6 className={styles.agoraTitle}>
          {agora?.ago_title}
        </h6>
      </div>
    )
  }


  return (
    <div className={styles.container}>
      {isMobile ? <TopBar /> : <Header title={t('groups')} />}
      <div id='content' className={styles.content}>

        <InfiniteScroll
          dataLength={agoras?.length} //This is important field to render the next data
          next={() => null}
          hasMore={false}
          loader={<Spinner />}
          scrollableTarget="content">


          {agoraToKnow.length !== 0 &&
            <div style={{ borderBottom: '1px #f1f1f1 solid', paddingTop: '15px', paddingBottom: '15px', paddingLeft: '5px', paddingRight: '5px' }}>

              <h6 style={{ marginLeft: '10px' }}>
                {t('agora_you_might_be_interested_in')}
              </h6>
              <Carousel responsive={responsive}>

                {agoraToKnow.map((agora, index) => {

                  if (!agora?.users.some(item => item?.user_id === authData?.user?.user_id)) {
                    return (
                      <div key={index.toString()} className='render-people' >

                        <img src={agora.ago_picture ? agora.ago_picture : require('../../assets/images/background_opinion.jpg')}
                          width={'95%'}
                          height={isMobile ? 300 : 200}
                          className='profile-picture'
                          onClick={() => navigate(`/agora/${agora.ago_id}`)}
                        />
                        <div >
                          <h6 onClick={() => navigate(`/agora/${agora.ago_id}`, { state: { agora_title: agora?.ago_title } })} className='name'>{agora.ago_title.length > 20 ? `${agora.ago_title.substring(0, 20)}...` : agora.ago_title}</h6>
                          <p className='username'>{agora.users_count} participants</p>
                        </div>
                        <div className='button-box'>

                          {!agora?.users.some(item => item?.user_id === authData?.user?.user_id) &&
                            !agora?.pending_users.some(item => item?.user_id === authData?.user?.user_id) ?
                            <button
                              onClick={() => joinAnAgora(agora?.ago_id)}
                              className='button'
                              style={{
                                backgroundColor: color.primary,
                                color: color.white,
                                width: '100%',
                                marginLeft: '10px'
                              }}>
                              <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                                <TbPlugConnected size={18} color={color.white} />
                              </div>
                             <span style={{fontWeight: '500'}}>{t('participate')}</span> 
                            </button>
                            : null}

                          {agora?.pending_users.some(item => item.user_id === authData?.user?.user_id) &&
                            <button
                              onClick={() => deleteJoin(agora?.ago_id)}
                              className='button'
                              style={{
                                width: '100%',
                                backgroundColor: 'white',
                                color: color.primary,
                                fontWeight: '500',
                                border: `1.8px ${color.primary} solid`
                              }}>
                              <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                                <AiOutlineDisconnect size={18} color={color.primary} />
                              </div>
                              {t('cancel')}
                            </button>
                          }

                          {/* {agora?.users.some(item => item?.user_id === authData?.user?.user_id) &&
                          <button
                            // onClick={becomeOrDeleteFan}
                            className='button'
                            style={{
                              width: '100%',
                              backgroundColor: 'white',
                              color: "#1880a1",
                              fontWeight: '600',
                              border: '1.8px #1880a1 solid'
                            }}>
                            <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                              <AiOutlineDisconnect size={18} color={color.primary} />
                            </div>
                            {t('Quitter')}
                          </button>
                        } */}

                        </div>

                      </div>
                    )
                  }

                })}
              </Carousel>
            </div>
          }



          {/* RENDER BUTTNO CREATE AGORAS */}
          <ButtonSubmit
            onClick={() => setShowCreateAgora(true)}
            value={t('create_agora')}
            style={{
              fontSize: '18px',
              fontWeight: '500',
              color: 'white',
              backgroundColor: color.primary,
              width: '50%',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '20px',
              marginBottom: '20px'
            }}
          />


          {invitations.length !== 0 &&
            <div>
              <h6 style={{ fontWeight: 'bold', padding: '10px', color: color.white, backgroundColor: color.primary }}>
                {t('my_invites')}
              </h6>
              {invitations.map((agora, index) => {
                return (
                  <RenderAgora
                    key={index.toString()}
                    agora={agora}
                  />
                )
              })}
            </div>
          }

          {requests.length !== 0 &&
            <div>
              <h6 style={{ fontWeight: 'bold', padding: '10px', color: color.white, backgroundColor: color.primary }}>
                {t('my_requests')}
              </h6>
              {requests.map((agora, index) => {
                return (
                  <RenderAgora
                    key={index.toString()}
                    agora={agora}
                  />
                )
              })}
            </div>
          }

          {agoras.length !== 0 &&
            <div>
              <h6 style={{ fontWeight: 'bold', padding: '10px', marginBottom: '0px', color: color.white, backgroundColor: color.primary }}>
                {t('my_agoras')}
              </h6>
              {agoras.map((agora, index) => {
                return (
                  <RenderAgora
                    key={index.toString()}
                    agora={agora}
                  />
                )
              })}
            </div>
          }

        </InfiniteScroll>
      </div>



      {/* DIALOG CREATE GROUP */}
      <Dialog open={showCreateAgora} onClose={handleClose}>
        <DialogTitle>{t('create_agora')}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: '20px' }}>
            Entrer les informations de votre groupe.
          </DialogContentText>

          <span>Groupe privée</span>
          <Switch
            checked={isPrivate}
            onChange={(event) => setIsPrivate(event.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />

          <TextField
            autoFocus
            multiline={false}
            margin="dense"
            label={t('name_of_the_group')}
            value={agoraTitle}
            onChange={(event) => setAgoraTitle(event.target.value)}
            fullWidth
            variant="outlined"
            size='small'
            style={{ marginBottom: '15px', marginTop: '10px' }}
          />

          <TextField
            autoFocus
            margin="dense"
            label={t('description_of_the_group')}
            value={agoraDescription}
            onChange={(event) => setAgoraDescription(event.target.value)}
            fullWidth
            multiline
            variant="outlined"
            style={{ marginBottom: '15px' }}
          />

          {isLoadingCreate &&
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spinner />
            </div>
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          <Button onClick={createAgora}>{t('create')}</Button>
        </DialogActions>
      </Dialog>

      {/* <Modal show={showCreateAgora}>

        <Header title={t('create_agora')} type='modal' goBack={() => setShowCreateAgora(false)} />
        <div style={{ padding: '15px' }}>
          <TextInput
            value={agoraTitle}
            setValue={setAgoraTitle}
            type='text'
            placeholder={t('title')}
            style={{
              width: '100%',
              marginBottom: '10px'
            }}
          />
          <TextInput
            value={agoraDescription}
            setValue={setAgoraDescription}
            type='text'
            placeholder={'Description'}
            style={{
              width: '100%',
              marginBottom: '25px'
            }}
          />

          <ButtonSubmit
            onClick={createAgora}
            value={t('create')}
            style={{
              color: 'white',
              backgroundColor: color.primary,
              fontWeight: '600',
              width: '60%',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
            isLoading={isLoadingCreate}
          />
        </div>

      </Modal> */}

    </div>
  )

}

export default Agora